<template>
  <div class="app-conter">
    <div>
      <van-tabs
        sticky
        v-model="active"
        @click="title"
        title-inactive-color="#000000"
        title-active-color="#A78A48"
        line-width="20px"
        color="#A78A48"
      >
        <van-tab title="进行中" name="3">
          <van-list
            v-model="HaveInHandloading"
            :finished="HaveInHandfinished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <table class="tab">
              <thead class="tab-tby">
                <th>进度</th>
                <th>借款人</th>
                <th>手机号</th>
                <th>操作</th>
              </thead>

              <tbody v-for="(item, index) in HaveInHand" :key="index">
                <th
                  width="100px"
                  v-if="item.state == '新增订单'"
                  style="color: #48a79d"
                >
                  {{ item.state }}
                </th>
                <th width="100px" v-else style="color: #efc84f">
                  {{ item.state }}
                </th>
                <th width="100px">{{ item.customerName }}</th>
                <th width="120px" height="70px">{{ item.contactPhone }}</th>
                <th class="thbtn">
                  <div v-if="item.state === '新增订单'">
                    <van-button
                      style="background: rgba(167, 138, 72, 0.15)"
                      size="mini"
                      color="#A78A48"
                      plain
                      @click="edit(index)"
                      >编辑</van-button
                    >
                  </div>
                  <div>
                    <van-button
                      style="background: rgba(167, 138, 72, 0.15)"
                      size="mini"
                      color="#A78A48"
                      plain
                      @click="details(index)"
                    >
                      详情
                    </van-button>
                  </div>
                  <div v-if="item.state !== '新增订单'">
                    <van-button
                      style="background: rgba(167, 138, 72, 0.15)"
                      size="mini"
                      color="#A78A48"
                      plain
                      @click="speedofprogress(index)"
                      >进度</van-button
                    >
                  </div>
                </th>
              </tbody>
            </table>
          </van-list>
        </van-tab>

        <van-tab title="已放款" name="1">
          <van-list
            v-model="Disbursedloading"
            :finished="Disbursedfinished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <table class="tab">
              <thead class="tab-tby">
                <th>进度</th>
                <th>借款人</th>
                <th>手机号</th>
                <th>操作</th>
              </thead>
              <tbody v-for="(item, index) in Disbursed" :key="index">
                <th width="100px" style="color: #a78a48">{{ item.state }}</th>
                <th width="100px">{{ item.customerName }}</th>
                <th width="120px">{{ item.contactPhone }}</th>
                <th class="thbtn">
                  <div>
                    <van-button
                      style="background: rgba(167, 138, 72, 0.15)"
                      size="mini"
                      color="#A78A48"
                      plain
                      @click="details(index)"
                      >详情</van-button
                    >
                  </div>
                  <div>
                    <van-button
                      style="background: rgba(167, 138, 72, 0.15)"
                      size="mini"
                      color="#A78A48"
                      plain
                      @click="speedofprogress(index)"
                      >进度</van-button
                    >
                  </div>
                </th>
              </tbody>
            </table>
          </van-list>
        </van-tab>

        <van-tab title="无效订单" name="2">
          <van-list
            v-model="Invalidorderloading"
            :finished="Invalidorderfinished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <table class="tab">
              <thead class="tab-tby">
                <th>进度</th>
                <th>借款人</th>
                <th>手机号</th>
                <th>操作</th>
              </thead>
              <tbody v-for="(item, index) in Invalidorder" :key="index">
                <th width="100px" style="color: #a75248">{{ item.state }}</th>
                <th width="100px">{{ item.customerName }}</th>
                <th width="120px">{{ item.contactPhone }}</th>
                <th class="thbtn">
                  <div>
                    <van-button
                      style="background: rgba(167, 138, 72, 0.15)"
                      size="mini"
                      color="#A78A48"
                      plain
                      @click="details(index)"
                      >详情</van-button
                    >
                  </div>
                  <div>
                    <van-button
                      style="background: rgba(167, 138, 72, 0.15)"
                      size="mini"
                      color="#A78A48"
                      plain
                      @click="speedofprogress(index)"
                      >进度</van-button
                    >
                  </div>
                </th>
              </tbody>
            </table>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { getManagerOrderGzhList } from "../../api/order/order";
export default {
  name: "",
  data() {
    return {
      HaveInHandloading: false,
      HaveInHandfinished: false,
      Disbursedloading: false,
      Disbursedfinished: false,
      Invalidorderloading: false,
      Invalidorderfinished: false,
      active: 3,
      orderNumber: "",
      orderId: "",
      // 进行中数据
      HaveInHand: [],
      // 已放款数据
      Disbursed: [],
      // 无效订单数据
      Invalidorder: [],
      // 请求参数
      ManagerOrderGzhListdata: {
        pageNum: 1,
        pageSize: 500,
        status: 3,
      },
    };
  },
  methods: {
    // 下拉刷新列表
    onLoad() {
      // 异步更新数据
      this.ManagerOrderGzhListdata.pageNum++;
      this.potsgetManagerOrderGzhList();
    },

    // 点击切换 tab
    title(name) {
      // 重置页数
      this.ManagerOrderGzhListdata.pageNum = 1;
      // 点击所在tab时不进行请求
      this.active = name;
      if (this.ManagerOrderGzhListdata.status !== name) {
        this.ManagerOrderGzhListdata.status = name;
        this.potsgetManagerOrderGzhList();
      }
      // 只有第一次点击时 进行请求
    },
    // 获取订单记录列表数据
    potsgetManagerOrderGzhList() {
      getManagerOrderGzhList(this.ManagerOrderGzhListdata).then((res) => {
        if (res.data.code == 0) {
          // active是3 为进行中数据
          if (this.active == 3) {
            if (res.data.data.total == this.HaveInHand.length) {
              this.HaveInHandfinished = true;
              return;
            }
            this.HaveInHand = [...this.HaveInHand, ...res.data.data.rows];
            this.HaveInHandloading = false;
            // active是1 为已放款数据
          } else if (this.active == 1) {
            if (res.data.data.total == this.Disbursed.length) {
              this.Disbursedfinished = true;
              return;
            }
            this.Disbursed = [...this.Disbursed, ...res.data.data.rows];
            this.Disbursedloading = false;
            // active是2 为无效订单数据
          } else if (this.active == 2) {
            if (res.data.data.total == this.Invalidorder.length) {
              this.Invalidorderfinished = true;
              return;
            }
            this.Invalidorder = [...this.Invalidorder, ...res.data.data.rows];
            this.Invalidorderloading = false;
          }
        }
      });
    },
    // 订单详情
    details(index) {
      this.getclick(index);
      this.$router.push({
        path: "/OrderDetails",
        query: {
          orderNumber: this.orderNumber,
          orderId: this.orderId,
        },
      });
    },
    // 订单进度
    speedofprogress(index) {
      this.getclick(index);
      this.$router.push({
        path: "/orderProgress",
        query: {
          orderNumber: this.orderNumber,
          orderId: this.orderId,
        },
      });
    },
    // 编辑
    edit(index) {
      this.getclick(index);
      this.$router.push({
        path: "/incomingParts",
        query: {
          orderNumber: this.orderNumber,
          orderId: this.orderId,
        },
      });
    },
    // 获取当前点击订单数据
    getclick(index) {
      if (this.active == 3) {
        this.orderNumber = this.HaveInHand[index].orderNumber;
        this.orderId = this.HaveInHand[index].orderId;
      } else if (this.active == 1) {
        this.orderNumber = this.Disbursed[index].orderNumber;
        this.orderId = this.Disbursed[index].orderId;
      } else if (this.active == 2) {
        this.orderNumber = this.Invalidorder[index].orderNumber;
        this.orderId = this.Invalidorder[index].orderId;
      }
    },
  },
  created() {
    this.potsgetManagerOrderGzhList();
  },
};
</script>

<style scoped>
.app-conter {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.main {
  flex: 1;
  overflow: scroll;
}
.warp {
  flex: 1;
}
.warp-title {
  width: 33%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #f4f7fa;
  color: #af9999;
  border: 1px solid #bbb;
}
.warp-tabs {
  display: flex;
  justify-content: space-around;
}
.warp-title-active {
  width: 33%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #fff;
  color: #1abc9c;
  border: 1px solid rgb(230, 224, 224);
}

.tab-tby {
  border-bottom: 1px solid #bbb;
  font-size: 14px;
  height: 50px;
}
.tab-tby th {
  height: 50px;
}
.tab tbody {
  font-size: 12px;

  border-bottom: 1px solid #bbb;
}
.foot {
  width: 100% - 20px;
  height: 60px;
  background: #f2f2f2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

.tab {
  width: 100%;
  border-top: 1px solid #bbb;
  margin-top: 8px;
  padding-top: 10px;
}
.thbtn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  height: 70px;
}
</style>
